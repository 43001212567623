
function formatNumber(num) {
  return Number(num).toLocaleString(undefined, { maximumFractionDigits: 2 });
}

function formatCurrencyNoSymbol(num) {
  return Number(num).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
}

function formatCurrencyWithSymbol(num) {
  return Strings.CurrencySymbol + formatCurrencyNoSymbol(num);
}

function DataTableRenderFormatNumber(data, type) {
  return ('display' == type)
    ? formatNumber(data)
    : data;
}

function DataTableRenderFormatCurrencyNoSymbol(data, type) {
  return ('display' == type)
    ? formatCurrencyNoSymbol(data)
    : data;
}

function DataTableRenderFormatCurrencyWithSymbol(data, type) {
  return ('display' == type)
    ? formatCurrencyWithSymbol(data)
    : data;
}

function getQueryValue(name) {
  return _.find(
    location.search.substr(1)
    .split('&')
    .map(function(q) {
      var p = q.split('=');
      return p.length >= 2 && decodeURIComponent(p[0]) == name
        ? decodeURIComponent(p[1])
        : null;
    }),
    function(p) { return p != null });
}

function encodeHTML(text) {
  return $('<span/>').text(text).html();
}
