(function(jQuery) {

  jQuery.eventEmitter = {
    _JQInit: function() {
      this._JQ = jQuery(this);
    },
    emit: function(evt, data) {
      !this._JQ && this._JQInit();
      this._JQ.trigger(evt, data);
    },
    once: function(evt, handler) {
      !this._JQ && this._JQInit();
      this._JQ.one(evt, handler);
    },
    on: function(evt, handler) {
      !this._JQ && this._JQInit();
      this._JQ.bind(evt, handler);
    },
    off: function(evt, handler) {
      !this._JQ && this._JQInit();
      this._JQ.unbind(evt, handler);
    }
  };


  function GameEventer() {
    // do stuff
  }

  jQuery.extend(GameEventer.prototype, jQuery.eventEmitter);

  window.GameEvents = new GameEventer();

}(jQuery));

var getDurationDisplayString = function(seconds) {
  var duration = moment.duration(seconds, 'seconds');
  var months = duration.months();
  var days = duration.days();
  var hours = duration.hours();
  var minutes = duration.minutes();
  var secondsRemainder = duration.seconds();

  var displayString = "";
  if (months > 0) {
    displayString = months + " months " + days + " days";
  } else if (days > 0) {
    displayString = days + " days " + hours + " hours";
  } else if (hours > 0) {
    displayString = hours + " hours " + minutes + " minutes";
  } else {
    displayString = minutes + " minutes " + secondsRemainder + " seconds";
  }

  return displayString;
}



//Sync/Fetch the Timer values

//these vars match the json bag from /home/time
var GlobalGameTime = { GameYear: 0, GameYearSeconds: 0, SecondsPerGameYear: 0, State: 0, initial: true };
var secondsInAYear = 31536000; //seconds in a real life year
var Scale = 1; //This gets set once we know what SecondPerGameYear is
var AllowanceAuctionScheduleData;
var currentAllowanceAuction = { StartTime: 0, EndTime: 0, ScheduleIndex: 0, State: 0, NextStartTime: 0, isLastInYear: 0 };
var realtimeclock = { millisecondsOffset: 0, editing: false };
var remainingSeconds = 0;
var last30sAlert, lastStartAlert;
var padNumber = function(number) {
  if (number <= 0) {
    return "00";
  }
  if (number < 10) {
    return "0" + number;
  }
  return number;
};
var updateRealTimeClock = function() {
  if (realtimeclock.editing == false && $('#clock-timer .timer').length > 0) {
    var now = new Date();
    var utcDate = new Date(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate(), now.getUTCHours(), now.getUTCMinutes(), now.getUTCSeconds());
    var utcTime = utcDate.getTime();
    var d = new Date(utcTime + realtimeclock.millisecondsOffset);

    $('#clock-timer .timer').html(padNumber(d.getHours()) + ":" + padNumber(d.getMinutes()));
  }
};

var getRealTimeClockData = function() {
  if ($('#clock-timer').length < 1)
    return;
  AjaxPartialEvent("/realtimeclock/gettime",
    null,
    function(data) {
      if (data && data.data) {
        realtimeclock.millisecondsOffset = data.data.secondsOffset * 1000;
        updateRealTimeClock();
      }
    }
  );
};

var getAllowanceAuctionScheduleData = function() {
  if ($('#allowance-auction-timer-text').length < 1)
    return;

  AjaxPartialEvent("/allowanceauctionschedule",
    null,
    function(data) {
      if ($.isArray(data) && data.length > 0) {
        AllowanceAuctionScheduleData = data[0];
        initAllowanceAuctionTimer();
      }
    }
  );
};

var initAllowanceAuctionTimer = function(startIndex) {

  startIndex = typeof startIndex !== 'undefined' ? startIndex : 0;

  //handle case where timer hasn't been started yet.
  if (typeof AllowanceAuctionScheduleData === 'undefined' || AllowanceAuctionScheduleData == null) {
    currentAllowanceAuction.StartTime = 0;
    currentAllowanceAuction.EndTime = 0;
    currentAllowanceAuction.ScheduleIndex = 0;
    currentAllowanceAuction.State = 0;
    currentAllowanceAuction.NextStartTime = 0;
    return;
  }
  var lastIndexForThisYear = 0;
  var currentGameYearSeconds = GlobalGameTime.SecondsPerGameYear - remainingSeconds;
  for (var index = startIndex; index < AllowanceAuctionScheduleData.length; ++index) {
    var allowanceAuction = AllowanceAuctionScheduleData[index];
    if ((allowanceAuction.Vintage.replace(/[^\d]/g, '') - 1) == GlobalGameTime.GameYear) {
      lastIndexForThisYear = index;
      if (allowanceAuction.GameYearSecondsOpen <= currentGameYearSeconds) {
        if (allowanceAuction.GameYearSecondsClose > currentGameYearSeconds) {
          //this is the current allowance auction and it's active
          currentAllowanceAuction.StartTime = allowanceAuction.GameYearSecondsOpen;
          currentAllowanceAuction.EndTime = allowanceAuction.GameYearSecondsClose;
          currentAllowanceAuction.ScheduleIndex = index;
          currentAllowanceAuction.State = 1;
          continue;
        }
        if (allowanceAuction.GameYearSecondsClose <= currentGameYearSeconds) {
          //this may be the current allowance auction but it would have ended by now
          currentAllowanceAuction.StartTime = allowanceAuction.GameYearSecondsOpen;
          currentAllowanceAuction.EndTime = allowanceAuction.GameYearSecondsClose;
          currentAllowanceAuction.ScheduleIndex = index;
          currentAllowanceAuction.State = 0;
          continue;
        }

      }

      if (allowanceAuction.GameYearSecondsOpen > currentGameYearSeconds) {
        //This allowance auction hasn't started yet, so we are either inbetween auctions, or still in the previous auction.
        currentAllowanceAuction.NextStartTime = allowanceAuction.GameYearSecondsOpen;
        break;
      }
    }
  }

  currentAllowanceAuction.isLastInYear = (lastIndexForThisYear == currentAllowanceAuction.ScheduleIndex);

};



var initGameTimers = function(eventHubProxy) {
  eventHubProxy.on('StartYear',
    function(now, year) {
      getRealTimeClockData();
      getAllowanceAuctionScheduleData();
    });

  getRealTimeClockData();
  getAllowanceAuctionScheduleData();

  var addAuctionToastMessage = function(msg) {
    if (window.isAdmin) {
      addHtmlToastMessage("<a href='/Admin/AllowanceAuction'>" + msg + "</a>");
    } else {
      addHtmlToastMessage("<a href='/AllowanceAuction'>" + msg + "</a>");
    }
  };

  var updateAllowanceAuctiontimer = function() {
    var currentGameYearSeconds = GlobalGameTime.SecondsPerGameYear - remainingSeconds;
    var allowanceAuctionRemainingSeconds = 0;
    if (currentAllowanceAuction.State == 1) {

      $('#allowance-auction-timer-text').closest('.panel').removeClass("panel-red");

      $('#allowance-auction-timer-text').text(Strings.AllowanceAuctionRemaining);
      allowanceAuctionRemainingSeconds = currentAllowanceAuction.EndTime - currentGameYearSeconds;
      // Use to fire "ends in 30 seconds" alert
      if (allowanceAuctionRemainingSeconds > 0 && (Math.abs(allowanceAuctionRemainingSeconds - 30) < 2) && (!last30sAlert || (currentGameYearSeconds - last30sAlert) > 30)) {
        addAuctionToastMessage(Strings.AuctionPreEndAlert);
        last30sAlert = currentGameYearSeconds;
      }
      if (allowanceAuctionRemainingSeconds <= 0) {
        addAuctionToastMessage(Strings.AuctionEndAlert);
        initAllowanceAuctionTimer(currentAllowanceAuction.ScheduleIndex);
      }
    } else if (currentAllowanceAuction.State == 0) {
      if (currentAllowanceAuction.isLastInYear) {
        $('#allowance-auction-timer-text').text(Strings.AllowanceAuctionTradingHalted);
        $('#allowance-auction-timer-text').closest('.panel').addClass("panel-red");
      } else {
        $('#allowance-auction-timer-text').closest('.panel').removeClass("panel-red");
        $('#allowance-auction-timer-text').text(Strings.AllowanceAuctionStartsIn);
      }
      if (currentAllowanceAuction.NextStartTime <= currentGameYearSeconds) {
        // Fire auction started alert
        if (currentAllowanceAuction.NextStartTime > 0 && (!lastStartAlert || currentAllowanceAuction.NextStartTime > lastStartAlert)) {
          lastStartAlert = currentAllowanceAuction.NextStartTime;
          addAuctionToastMessage(Strings.AuctionStartAlert);
        }

        initAllowanceAuctionTimer(currentAllowanceAuction.ScheduleIndex);
      } else {
        allowanceAuctionRemainingSeconds = currentAllowanceAuction.NextStartTime - currentGameYearSeconds;
        // Use to fire "starts in 30 seconds" alert
        if (allowanceAuctionRemainingSeconds > 0 && (Math.abs(allowanceAuctionRemainingSeconds - 30) < 2) && (!last30sAlert || (currentGameYearSeconds - last30sAlert) > 31)) {
          addAuctionToastMessage(Strings.AuctionPreStartAlert);
          last30sAlert = currentGameYearSeconds;
        }
      }
    }

    if (allowanceAuctionRemainingSeconds >= 0) {
      $('#allowance-auction-timer').text(getDurationDisplayString(allowanceAuctionRemainingSeconds));
    }
  };



  var updateExchangeMarketTimer = function() {
    if (remainingSeconds > 0) {
      $('#secondary-market-timer-text').closest('.panel').removeClass("panel-red");
      $('#secondary-market-timer-text').text(Strings.SecondaryMarketRemaining);
      $('#secondary-market-timer').text(getDurationDisplayString(remainingSeconds));
    } else {
      $('#secondary-market-timer-text').closest('.panel').addClass("panel-red");
      $('#secondary-market-timer-text').text(Strings.SecondaryMarketTradingHalted);
    }
  };

  var secondsBetweenSync = 10;
  //how many seconds to pass before polling the server clock again.
  var nextSyncCounter = secondsBetweenSync;
  var syncGameTime = function() {
    AjaxPartialEvent("/home/time",
      null,
      function(data) {
        // Diff check
        if (!GlobalGameTime.initial) {
          if (data.State != GlobalGameTime.State) {
            GameEvents.emit('statechange', { from: GlobalGameTime.State, to: data.State });
            if (data.State == 1) {
              GameEvents.emit('run', { from: GlobalGameTime.State });
              getRealTimeClockData();
              getAllowanceAuctionScheduleData();
            } else if (data.State == 2) {
              GameEvents.emit('pause', { from: GlobalGameTime.State });
            } else if (data.State == 3) {
              GameEvents.emit('tradinghalted', { from: GlobalGameTime.State });
            } else if (data.State == 4) {
              GameEvents.emit('yearended', { from: GlobalGameTime.State });
            }
          }
        } else {
          if (data.State == 4) {
            $('#lbbutton').show();
          }
        }

        GlobalGameTime = data;
        remainingSeconds = Math.max(data.SecondsPerGameYear - data.GameYearSeconds, 0);
        Scale = secondsInAYear / GlobalGameTime.SecondsPerGameYear;
      }
    );
  };
  syncGameTime();


  setInterval(function() {

      updateRealTimeClock();
      if (typeof GlobalGameTime === 'undefined' || GlobalGameTime == null || GlobalGameTime.SecondsPerGameYear == 0) {
        return;
      }

      //var minutes = padNumber(Math.floor(remainingSeconds / 60));
      //var seconds  = padNumber(Math.floor(remainingSeconds % 60));

      $('#time-left-timer .timer').text(getDurationDisplayString(remainingSeconds));

      var d = new Date("1970-01-01T00:00:00");

      if (remainingSeconds < 1) {
        d.setSeconds((GlobalGameTime.SecondsPerGameYear - 1) * Scale);
      } else {
        d.setSeconds((GlobalGameTime.SecondsPerGameYear - remainingSeconds) * Scale);
      }
      $('#year-remaining-timer').text(formatDateGameLong(GlobalGameTime.GameYear, d));

      updateAllowanceAuctiontimer();
      updateExchangeMarketTimer();

      if (GlobalGameTime.State == 1 && remainingSeconds > 0) { //Running
        --remainingSeconds;
      }
      --nextSyncCounter;
      if (nextSyncCounter == 0) {
        syncGameTime();
        nextSyncCounter = secondsBetweenSync;
      }
    },
    1000);

  $('#clock-timer .edit').on('click',
    function() {
      realtimeclock.editing = true;
      var now = new Date();
      var utcDate = new Date(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate(), now.getUTCHours(), now.getUTCMinutes(), now.getUTCSeconds());
      var utcTime = utcDate.getTime();
      var d = new Date(utcTime + realtimeclock.millisecondsOffset);

      $('#clock-timer .edit').hide();
      $('#clock-timer .set').show();
      $('#clock-timer .timer').html('<form><input name="clock-set-hour" class="clock-set-hour" type="number" min="0" max="23" value="'
        + padNumber(d.getHours())
        + '" />:<input name="clock-set-minutes" class="clock-set-minutes" type="number" min="0" max="59" value="'
        + padNumber(d.getMinutes())
        + '" /></form>');

    });
  $('#clock-timer .set').on('click',
    function() {

      $('#clock-timer .set').hide();
      $('#clock-timer .edit').show();

      AjaxPartialEvent("/realtimeclock/settime",
        null,
        function(data) {
          if (data && data.data) {
            realtimeclock.millisecondsOffset = data.data.secondsOffset * 1000;
            updateRealTimeClock();
          }
        },
        function() {
          var now = new Date();
          var hoursOffset = $('.clock-set-hour').val() - now.getUTCHours();
          var minutesOffset = $('.clock-set-minutes').val() - now.getUTCMinutes();
          return { 'minutesOffset': ((60 * hoursOffset) + (minutesOffset)) };
        }
      );

      realtimeclock.editing = false;
      updateRealTimeClock();
    });


};
