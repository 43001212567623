var initLocaleEditor = function(idHidden, propertyName, idAppendTo, idInputElement, controlClass) {

  // Element identification
  var dataFor = idHidden + '.' + propertyName;
  var dataForFilter = function() { return dataFor == this.getAttribute('data-for'); };

  // Element lookup
  var jqHidden = $(document.getElementById(idHidden));
  var jqAppendTo = $(document.getElementById(idAppendTo));
  if (0 == jqHidden.length || 0 == jqAppendTo)
    throw 'initLocaleEditor() failed - elements missing';
  var jqSelect = jqAppendTo.find('select').filter(dataForFilter).first();
  var jqInput = jqAppendTo.find('input').filter(dataForFilter).first();

  // Behaviours
  var populateInput = function() {
    try {
      jqInput.val(JSON.parse(jqHidden.val())[jqSelect.val()][propertyName]);
    } catch (e) {
      jqInput.val('');
    }
  };
  var applyEdits = function() {
    var dataObj;
    try {
      dataObj = JSON.parse(jqHidden.val());
    } catch (e) {
      dataObj = {};
    }
    var cultureName = jqSelect.val();
    if (!dataObj[cultureName])
      dataObj[cultureName] = {};
    dataObj[cultureName][propertyName] = jqInput.val();
    jqHidden.val(JSON.stringify(dataObj));
  };

  // Init?
  if (0 == jqSelect.length && 0 == jqInput.length) {
    // Init select
    jqSelect = $(document.createElement('select'));
    jqSelect.attr('data-for', dataFor);
    jqSelect.addClass('locale-editor');
    if (controlClass)
      jqSelect.addClass(controlClass);
    var optCount = 0;
    for (var name in window.DataTranslationCultures) {
      var option = $(document.createElement('option'));
      option.attr('value', name);
      option.append(document.createTextNode(window.DataTranslationCultures[name]));
      jqSelect.append(option);
      optCount++;
    }
    if (optCount < 2) {
      jqSelect.prop('disabled', true);
    }
    jqAppendTo.append(jqSelect);
    if (optCount < 1) {
      return;
    }

    // Init input
    jqInput = $(document.createElement('input'));
    jqInput.attr('data-for', dataFor);
    if (idInputElement)
      jqInput.attr('id', idInputElement);
    jqInput.addClass('locale-editor');
    if (controlClass)
      jqInput.addClass(controlClass);
    jqSelect.after(jqInput);

    // Initially and on selection change, populate input.
    jqSelect.on('focus', applyEdits);
    jqSelect.on('change', populateInput);

    // On edit, update JSON
    jqInput.on('propertychange change click keyup input paste', applyEdits);
  }

  // Init or re-init value from JSON
  if (0 != jqSelect.length && 0 != jqInput.length) {
    populateInput();
  }
};

var initBootstrapLocaleEditor = function(idHidden, propertyName, idAppendTo, idInputElement) {
  initLocaleEditor(idHidden, propertyName, idAppendTo, idInputElement, 'form-control');
};
