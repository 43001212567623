
var bootstrapAlert = function(jqParent, msgText, type) {
  type = type || 'danger';

  var jqAlert =
    $('<div class="alert alert-' + type + ' alert-dismissible" role="alert"><button type="button" class="close" data-dismiss="alert"><span aria-hidden="true">&times;</span></button></div>');
  jqAlert.append(document.createTextNode(msgText));
  jqParent.append(jqAlert);
};

var initAjaxPartialForm = function(jqForm, jqActionArea, onSuccess, customFormData) {
  jqForm.submit(function(event) {
    event.preventDefault();

    if (!$(this).valid()) {
      return;
    }

    if (!customFormData) {
      customFormData = function() {
        return jqForm.serialize();
      };
    }

    $('body').css({ 'cursor': 'progress' });
    jqForm.find('.loaderAnimation').show();
    $.post(jqForm.attr('action'), customFormData())
      .always(function() {
        jqForm.find('.loaderAnimation').hide();
        $('body').css({ 'cursor': 'auto' });
      })
      .fail(function(jqXhr, textStatus, errorThrown) {
        bootstrapAlert(jqForm, Strings.UnableToContactServer + ' [' + Strings.ErrorThrown + ' "' + errorThrown + '"].');
      })
      .done(function(data) {
        if (data.success != null && !data.success) {
          displayErrorsFromModelState(jqForm, data.Errors);
          return;
        }
        if (jqActionArea != null && jqActionArea.length > 0)
          jqActionArea.html(data);
        if (onSuccess)
          onSuccess();
      });
  });
};

var AjaxPartialEvent = function(controllerAction, jqActionArea, onSuccess, customFormData) {

  if (!customFormData) {
    customFormData = function() {
      return "";
    };
  }

  $.post(controllerAction, customFormData())
    .always(function() {
      $('body').css({ 'cursor': 'auto' });
    })
    .fail(function(jqXhr, textStatus, errorThrown) {
    })
    .done(function(data) {
      if (data.success != null && !data.success) {
        return;
      }
      if (jqActionArea != null && jqActionArea.length > 0)
        jqActionArea.html(data);
      if (onSuccess)
        onSuccess(data);
    });
};

var initAjaxPartialButton = function(jqButton, jqActionArea, onSuccess) {
  jqButton.click(function(event) {
    event.preventDefault();

    $('body').css('cursor', 'progress');
    jqButton.append('<img class="loaderAnimation" src="/static/images/loader.gif" style="margin: 5px; vertical-align: top;" />');
    $.get(jqButton.attr('href'), jqButton.serialize())
      .always(function() {
        jqButton.find('.loaderAnimation').remove();
        $('body').css('cursor', 'auto');
      })
      .fail(function(jqXhr, textStatus, errorThrown) {
        bootstrapAlert(jqButton, Strings.UnableToContactServer + ' [' + Strings.ErrorThrown + ' "' + errorThrown + '"].');
      })
      .done(function(data) {
        jqActionArea.html(data);
        if (onSuccess)
          onSuccess();
      });
  });
};

var initAjaxPartialSelect = function(jqForm, jqActionArea, onSuccess) {
  $("select", jqForm).change(function(event) {
    event.preventDefault();

    $('body').css('cursor', 'progress');
    jqForm.append('<img class="loaderAnimation" src="/static/images/loader.gif" style="margin: 5px; vertical-align: top;" />');
    $.post(jqForm.attr('action'), jqForm.serialize())
      .always(function() {
        jqForm.find('.loaderAnimation').remove();
        $('body').css('cursor', 'auto');
      })
      .fail(function(jqXhr, textStatus, errorThrown) {
        bootstrapAlert(jqForm, Strings.UnableToContactServer + ' [' + Strings.ErrorThrown + ' "' + errorThrown + '"].');
      })
      .done(function(data) {
        jqActionArea.html(data);
        if (onSuccess)
          onSuccess();
      });
  });
};

var processForm = function(form, onResult, customFormData, onFail) {
  var jqForm = $(form);
  jqForm.submit(function(event) {
    event.preventDefault();

    if ($(this).valid && !$(this).valid()) {
      return false;
    }

    customFormData = customFormData
      || function() {
        return jqForm.serialize();
      };


    $('body').css({ 'cursor': 'progress' });
    jqForm.find('.loaderAnimation').show();

    $.post(jqForm.attr('action'), customFormData())
      .always(function() {
        jqForm.find('.loaderAnimation').hide();
        $('body').css({ 'cursor': 'auto' });
      })
      .fail(onFail
        || function(jqXhr, textStatus, errorThrown) {
          displayGeneralError(jqForm, Strings.UnableToContactServer);
          console.log(Strings.UnableToContactServer + ' [' + Strings.ErrorThrown + ' "' + errorThrown + '"].');
        })
      .done(function(data) {
        onResult(data, jqForm);
      });
    return false;
  });

}

var setValidityFromAjaxException = function(jqInput, jqXhr) {
  // Display modelstate errors
  var fieldErrors = jqXhr && jqXhr.responseJSON && jqXhr.responseJSON.errors && jqXhr.responseJSON.errors.filter(function(o) { return o.hasOwnProperty('Key') && o.hasOwnProperty('Message') });
  if (fieldErrors && fieldErrors.length > 0) {
    var elForm = jqInput[0].form;
    fieldErrors.forEach(function(fieldError) {
      var elField = elForm[fieldError.Key];
      if (elField) {
        elField.setCustomValidity(fieldError.Message);
        elField.form.reportValidity();
        $(elField).one('input change',
          function() {
            elField.setCustomValidity('');
          });
      }
    });
    return;
  }

  // Display general error
  var msg = (jqXhr && jqXhr.responseJSON && jqXhr.responseJSON.error) || Strings.UnableToContactServer;
  var elInput = jqInput[0];
  elInput.setCustomValidity(msg);
  elInput.form.reportValidity();
  $(elInput.form).one('input change',
    function() {
      elInput.setCustomValidity('');
    });
};
