/*
* functions which make form(s) with mvc unobtrusive validation compatible with bootstrap error styling.
*/



var oldErrorFunction;

function setupUnobtrusiveBootstrap(form, modelStateErrors, hideGeneralErrorOnSubmit) {
  if (form.length == 0)
    return;

  //Fix for forms loaded as ajax partials. Remove validator and add back again
  form.removeData('validator');
  form.removeData('unobtrusiveValidation');
  $.validator.unobtrusive.parse(form);


  if (hideGeneralErrorOnSubmit) {
    form.bind("submit",
      function() {
        var validationFailAlert = form.find('.validationFailAlert');
        if (validationFailAlert.length > 0)
          validationFailAlert.addClass("hidden");
      });
  }
  form.bind("invalid-form.validate",
    function() {
      var nearestForm = $(this).closest('form');
      showValidationFailAlert(nearestForm);
    });

  form.each(function(index, item) {

    var validator = $.data(item, 'validator');

    if (validator != null) {
      var settngs = validator.settings;

      var oldErrorFunction = settngs.errorPlacement;

      settngs.errorPlacement = function(error, inputElement) {
        depictError(error.text(), inputElement, form);
        if (oldErrorFunction)
          oldErrorFunction(error, inputElement);
      };
    }
  });
  displayErrorsFromModelState(form, modelStateErrors);
}

function displayGeneralError(form, message) {
  hideSuccess(form);
  var generalError = form.find('.general-error');
  generalError.text(message);
  generalError.show();
  form.find('.form-errors').removeClass("hidden");
}

function displaySuccess(form, message, withScroll) {
  var $form = $(form);
  var $success = $form.find('.form-success');
  $success.text(message);
  $success.removeClass("hidden");
  hideErrors(form);
  if (withScroll)
    $success.scrollIntoView();
}

function hideSuccess(form) {
  var $form = $(form);
  var $success = $form.find('.form-success');
  $success.addClass("hidden");
}

function hideErrors(form) {
  var $form = $(form);
  // get validator object
  var validator = $form.validate();

  // get errors that were created using jQuery.validate.unobtrusive
  var errors = $form.find(".field-validation-error span");

  // trick unobtrusive to think the elements were succesfully validated
  // this removes the validation messages
  errors.each(function() { validator.settings.success($(this)); });

  var generalError = $form.find('.general-error');
  generalError.hide();

  $form.find('.validationFailAlert').addClass("hidden");
  $form.find('.form-errors').addClass("hidden");
}

function displayErrorsFromModelState(form, modelStateErrors) {
  var $form = $(form);
  //Check we have errors from server
  if (modelStateErrors == null || modelStateErrors.length == 0)
    return;
  var validator = $form.validate();
  var generalError = $form.find('.general-error');
  generalError.hide();
  var errors = {}; //"AllowanceAuctionInterval": "ssssss"

  modelStateErrors.forEach(function(error) {
    if (error.Key == "GeneralError") {
      displayGeneralError($form, error.Message);
      return;
    }
    errors[error.Key] = error.Message;
  });
  validator.showErrors(errors);
  showValidationFailAlert($form);
}


function showValidationFailAlert(form) {
  hideSuccess(form);
  var validationFailAlert = form.find('.validationFailAlert');
  if (validationFailAlert.length > 0)
    validationFailAlert.removeClass("hidden");
  form.find('.form-errors').removeClass("hidden");
}

function depictError(errorMessage, inputElement, form) {
  var formGroup = inputElement.closest(".form-group");
  var fieldMod = formGroup.find(".form-control-feedback");
  if (errorMessage == "") {
    formGroup.removeClass("has-error");
    formGroup.removeClass("has-feedback");
    if (fieldMod.length > 0) {
      fieldMod.addClass("hidden");
    }
  } else {

    formGroup.addClass("has-error");
    formGroup.addClass("has-feedback");
    if (fieldMod.length > 0) {
      fieldMod.removeClass("hidden");
    }
  }

}
