if (!$.fn.dataTable.render.text) {
  $.fn.dataTable.render.text = function() {
    return {
      display: function(d) {
        return typeof d === 'string' ? d.replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/"/g, '&quot;') : d;
      }
    };
  };
}

$.fn.dataTable.defaults.column.mRender = $.fn.dataTable.render.text();
