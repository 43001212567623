
var filterWithinStdDev = function(array, valueSelector, deviations) {
  deviations = deviations || 1;

  var avg = _(array).reduce(function(acc, element) {
        return acc + valueSelector(element);
      },
      0)
    / array.length;

  var stddev = Math.sqrt(
    _(array).reduce(function(acc, element) {
        return acc + Math.pow(valueSelector(element) - avg, 2);
      },
      0)
    / array.length
  );

  var filtCeil = avg + stddev * deviations;
  var filtFloor = avg - stddev * deviations;
  return _(array).filter(function(element) {
    return valueSelector(element) >= filtFloor
      && valueSelector(element) <= filtCeil;
  });
};
